import React from 'react'
import { Divider } from 'primereact/divider';
import profile from '../Images/4.jpg'
import { FaBootstrap, FaCss3, FaGithub, FaHtml5, FaJs, FaLaravel, FaNpm, FaPhp, FaReact } from 'react-icons/fa';
import FormMessage from './FormMessage';
import CV from '../assets/CV/Georgie_Updated.pdf'
import { Button } from 'primereact/button';
import { SiMicrosoftsqlserver, SiMysql } from "react-icons/si";
import {Badge} from 'primereact/badge'

function AboutMe() {
    return (
        <div>
            <h4 className='text-white'>My Profile</h4>
            <div className="flex flex-col justify-center items-center">
                <img src={profile} alt="ph" width={200} className="mb-3 opv " />
                <div className="container mb-3">
                    <ul>
                        <li className='mb-2 text-white md:text-center sm:text-start'><b>Name:</b> <span className='text-gray-100'> Georgie Mordeno Recabo</span></li>
                        <li className='mb-2 text-white md:text-center sm:text-start'><b>City:</b> <span className='text-gray-100'> Philippines, Butuan City</span></li>
                        <li className='mb-2 text-white md:text-center sm:text-start'><b>Degree:</b> <span className='text-gray-100'> Bachelor</span></li>
                        <li className='mb-2 text-white md:text-center sm:text-start'><b>Email:</b> <span className='text-gray-100'> artamay1@gmail.com</span></li>
                    </ul>
                </div>
            </div>
            <Divider><h4 className='text-white'>About me</h4></Divider>
            <div className="container mb-3">
                <div className="text-container mt-4">
                    <p className='text-parag md:text-base sm:text-base  leading-relaxed '>
                        <p>
                            I am committed to crafting code that is clean and easily maintainable, placing a strong emphasis on teamwork and collaboration. My passion lies in simplifying complex problems through streamlined and sophisticated solutions. Staying abreast of the latest technological advancements is a cornerstone of my approach, ensuring that my work remains in line with current industry standards. Additionally, I possess extensive experience in database management and have proficiency in deploying solutions using Ubuntu, often taking the lead in setting up Virtual Private Servers (VPS). I have hands-on experience with Git, utilizing it for version control in various projects. I'm proficient in managing repositories, branching, merging, resolving conflicts, and collaborating on code with teams. I am comfortable using Git commands and tools to streamline development workflows and maintain organized codebases.
                        </p>
                    </p>
                </div>
            </div>
            <Divider><h4 className='text-white'>Experience</h4></Divider>
            <div className="container mb-3">
                <div className="text-container mt-4">
                    <p className='text-parag md:text-base sm:text-base leading-normal '>
                        <div className="mb-4">
                            Freelancer  - <b>Software Developer </b>
                            <div className="mt-2">
                                <p className='text-parag'>
                                    I successfully conceptualized and built a web application from scratch, effectively meeting and exceeding my client's requirements and expectations, showcasing my ability to deliver bespoke solutions independently
                                </p>
                                <div className="d-flex mt-0">
                                    <FaLaravel size={40} className="me-2" style={{ color: "#F33020" }}></FaLaravel>
                                    <FaHtml5 size={40} className="me-2" style={{ color: "#F36620" }}></FaHtml5>
                                    <FaCss3 size={40} className="me-2" style={{ color: "#2083F3" }}></FaCss3>
                                    <FaBootstrap size={40} className="me-2" style={{ color: "#A020F3" }}></FaBootstrap>
                                    <FaReact size={40} className="me-2" style={{ color: "#2083F3" }}></FaReact>
                                    <FaNpm size={40} className="me-2" style={{ color: "#B3ABB8" }}></FaNpm>
                                    <FaPhp size={40} className="me-2" style={{ backgroundColor: '#7377AD', color: 'black', borderRadius: '100%' }} />
                                    <FaGithub size={40} className="me-2" />
                                    <SiMysql size={40} style={{ color: "#007690" }} />
                                    <FaJs size={40} className="me-2" style={{ color: "#F3C920" }}></FaJs>
                                </div>
                            </div>
                        </div>
                        <Divider></Divider>
                        <div className="mb-4">
                            iTECH-RAR Solutions, Inc. - <b>Junior Developer </b>
                            <div className="mt-2">
                                <p className='text-parag'>
                                    As a junior developer, I played a pivotal role in developing a robust payroll system and overseeing the management of five school systems, addressing and resolving errors promptly whenever clients raised concerns, demonstrating my commitment to delivering reliable and efficient software solutions.
                                </p>
                                <div className="d-flex mt-0">
                                    <FaLaravel size={40} className="me-2" style={{ color: "#F33020" }}></FaLaravel>
                                    <FaHtml5 size={40} className="me-2" style={{ color: "#F36620" }}></FaHtml5>
                                    <FaCss3 size={40} className="me-2" style={{ color: "#2083F3" }}></FaCss3>
                                    <FaBootstrap size={40} className="me-2" style={{ color: "#A020F3" }}></FaBootstrap>
                                    <FaPhp size={40} className="me-2" style={{ backgroundColor: '#7377AD', color: 'black', borderRadius: '100%' }} />
                                    <FaGithub size={40} className="me-2" />
                                    <SiMysql size={40} style={{ color: "#007690" }} />
                                    <FaJs size={40} className="me-2" style={{ color: "#F3C920" }}></FaJs>
                                </div>
                            </div>
                        </div>
                        <Divider></Divider>
                        <div className="mb-4">
                            ICargo Pacific Technologies  - <b>Full Stack Developer</b>
                            <div className="mt-2">
                                <p className='text-parag'>
                                    I am a full stack developer at ICargo Pacific Technologies, a startup company. I spearheaded the development of a project titled "Empowering Logistics & Cargo Business With Advanced Software Solutions System 2023". Prior to initiating this project, I meticulously crafted a database structure design, integrated QR and Bar Code Scanning functionalities, established parcel tracking mechanisms, and created branches for each logistic operation. Additionally, I successfully implemented third-party integrations within the system.
                                </p>
                                <div className="d-flex mt-0">
                                    <FaLaravel size={40} className="me-2" style={{ color: "#F33020" }}></FaLaravel>
                                    <FaHtml5 size={40} className="me-2" style={{ color: "#F36620" }}></FaHtml5>
                                    <FaCss3 size={40} className="me-2" style={{ color: "#2083F3" }}></FaCss3>
                                    <FaBootstrap size={40} className="me-2" style={{ color: "#A020F3" }}></FaBootstrap>
                                    <FaReact size={40} className="me-2" style={{ color: "#2083F3" }}></FaReact>
                                    <FaNpm size={40} className="me-2" style={{ color: "#B3ABB8" }}></FaNpm>
                                    <FaPhp size={40} className="me-2" style={{ backgroundColor: '#7377AD', color: 'black', borderRadius: '100%' }} />
                                    <FaGithub size={40} className="me-2" />
                                    <SiMysql size={40} style={{ color: "#007690" }} />
                                    <FaJs size={40} className="me-2" style={{ color: "#F3C920" }}></FaJs>
                                </div>
                            </div>
                        </div>
                        <Divider></Divider>
                        <div className="mb-4">
                            Mapua University   - <b>Senior Software Developer</b> <span className='text-green-500 text-lg font-medium'>Present</span> 
                            <div className="mt-2">
                                <p className='text-parag'>
                                    As a Senior Software Developer at Mapúa University, I am responsible for developing applications and enhancing user interactions to improve the overall user experience. I also oversee the maintenance of these applications to ensure their smooth operation. Additionally, I share my expertise with colleagues by providing guidance on effectively using Git.
                                </p>
                                <div className="d-flex mt-0">
                                    <FaHtml5 size={40} className="me-2" style={{ color: "#F36620" }}></FaHtml5>
                                    <FaCss3 size={40} className="me-2" style={{ color: "#2083F3" }}></FaCss3>
                                    <FaBootstrap size={40} className="me-2" style={{ color: "#A020F3" }}></FaBootstrap>
                                    <FaGithub size={40} className="me-2" />
                                    <SiMicrosoftsqlserver size={40}  style={{ color: "#007690" }} />
                                    <FaJs size={40} className="me-2" style={{ color: "#F3C920" }}></FaJs>
                                    
                                </div>
                            </div>
                        </div>          
                        <Divider></Divider>
                    </p>
                </div>
            </div>

            <Divider><h4 className='text-white'>Facts</h4></Divider>
            <div className="container mb-3">
                <div className="text-container mt-4">
                    <p className='text-parag '>- <small>Flexible and can easily adapt to a new environment, adequate skills in problem solving and logic analysis</small></p>
                    <p className='text-parag '>- <small>Good leadership skills with the ability to educate his fellow acquaintance.</small></p>
                </div>
            </div>
            {/* <Divider>
                <h4 className='text-white'>Social Connect</h4>
            </Divider>
            <div className="container mb-3">
                <div className="text-container mt-4">
                    <ul className='list'>
                        <li><p><a rel='noreferrer' href="https://github.com/grecabo123" target="_blank"><FaGithub size={30} style={{ color: "white" }} /></a></p></li>
                        <li><p><a rel='noreferrer' href="https://www.linkedin.com/in/georgie-recabo-7795b61b7/" target='_blank'><FaLinkedinIn size={30} style={{ color: "white" }} /></a></p></li>
                    </ul>
                </div>
            </div> */}
            {/* <Divider>
                <h4 className='text-white'>Resume</h4>
            </Divider>
            <a href={CV} download><Button label='Resume Download' className='p-button-sm p-button-info'></Button></a> */}
            <div className="container mb-3">
                <FormMessage></FormMessage>
            </div>
        </div>
    )
}

export default AboutMe